<template>
  <v-dialog :value="value" @input="(value) => $emit('change', value)" width="500" persistent>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
          <v-card-title class="text-h5"> Vinculando email de contato </v-card-title>
          <v-card-text>
            <e-label>Cadastre seu email</e-label>
            <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }" vid="email">
              <v-text-field :error-messages="errors" type="email" v-model="form.help_email" solo />
            </ValidationProvider>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              class="white--text"
              type="button"
              small
              @click="() => $emit('input', false)"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="submittingForm"
              :loading="submittingForm"
              small
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        help_email: "",
      },
      submittingForm: false,
    };
  },
  methods: {
    async submitForm() {
      this.submittingForm = true;
      try {
        await this.$services.authService.updateCurrentUser(this.form);
        this.$toast.success("Email atualizado com sucesso");
        this.$emit("input", false);
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
