<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione o mês e período </v-card-title>
        <v-card-text>
          <v-col class="d-flex" cols="12" sm="12">
            <v-select
              :items="meses"
              item-text="descricao"
              item-value="id"
              v-model="selectMonth"
              return-object
              label="Selecione um mês"
              solo
            ></v-select>
          </v-col>

          <v-col v-if="periodo" class="d-flex" cols="12" sm="12">
            <v-select
              :items="periodos"
              item-text="descricao"
              item-value="id"
              v-model="periodoSelect"
              return-object
              label="Selecione um periodo"
              solo
            ></v-select>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" @click="$emit('dialogChange', !dialog)"> {{ cancelText }} </v-btn>
          <v-btn color="primary" text @click="$emit('changeAction', selectMonth, periodoSelect)">
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    confirmText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
    periodo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectMonth: {
        id: 1,
        descricao: "Janeiro",
      },
      periodoSelect: {
        id: 1,
        descricao: "Periodo 1",
      },
      periodos: [],
      meses: [],
    };
  },
  async mounted() {
    try {
      this.meses = await this.$services.anosService.meses();
      this.periodos = await this.$services.anosService.periodos();
    } catch (error) {
      this.$handleError(error);
    }
  },
};
</script>

<style lang="scss" scoped></style>
