<template>
  <v-navigation-drawer width="30%" v-model="localDrawer" right temporary app>
    <v-list-item class="primary">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium white--text"
          >Histórico de aulas lançadas</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list class="px-3" v-for="aula in aulas" :key="aula.id" dense>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Conteúdo : </strong>
          <span>{{ aula.conteudo ? aula.conteudo : "- - -" }}</span>
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Data da aula: </strong>
          <span>{{ aula.data_formatada ? aula.data_formatada : "- - -" }}</span>
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Operações: </strong>
        </p>
      </v-list-item-title>
      <div v-if="aula.audits.length > 0">
        <v-list class="px-3" v-for="(audit, index) in aula.audits" :key="audit.id" dense>
          <v-list-item-title>
            <p class="font-weight-light subtitle-1 text-caption">
              Nome:
              <strong>{{ audit.username ? audit.username : "- - -" }}</strong>
            </p>
          </v-list-item-title>
          <v-list-item-title>
            <p class="font-weight-light subtitle-1 text-caption">
              Tipo de operação:
              <strong>{{ audit.event ? events[audit.event] : "- - -" }}</strong>
            </p>
          </v-list-item-title>
          <v-list-item-title>
            <p class="font-weight-light subtitle-1 text-caption">
              Data da operação:
              <strong>{{ audit.created_at ? dateFormat(audit.created_at) : "- - -" }}</strong>
              <strong>
                às {{ audit.created_at ? dateFormatHour(audit.created_at) : "- - -" }}</strong
              >
            </p>
          </v-list-item-title>
          <v-divider v-if="index + 1 != aula.audits.length"></v-divider>
        </v-list>
      </div>
      <v-list-item-title v-else>
        <strong class="subtitle-1 text-caption">Operações não encontradas</strong>
      </v-list-item-title>

      <v-list-item-title class="mt-2">
        <v-list-item-content>
          <v-divider></v-divider>
        </v-list-item-content>
      </v-list-item-title>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { dateFormat, dateFormatHour } from "@/plugins/filters";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    gestao_id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    async localDrawer() {
      this.$emit("input", this.localDrawer);
      if (this.localDrawer) {
        await this.carregarHistorico();
      }
    },
  },
  computed: {},
  data() {
    return {
      localDrawer: this.value,
      dialog: false,
      aulas: [],
      dateFormat,
      dateFormatHour,
      events: {
        created: "Criado",
        updated: "Atualizado",
        deleted: "Deletado",
        restored: "Restaurados",
      },
      masculino: "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png",
      feminino:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbUqkRN4QaEzCW4brgWElc7lv8HdwP8RyxJ89WdDWAoIO-rEUIACXkEXG4n2mDGMLuz4Q&usqp=CAU",
    };
  },
  methods: {
    async carregarHistorico() {
      this.$loaderService.open("Carregando histórico");
      try {
        const response = await this.$services.gestoesDeAulasService.historicoAulasLancadas(
          this.gestao_id
        );

        this.aulas = response.historicoLancamento;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
