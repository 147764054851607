import Axios from "@/plugins/Axios";

export class PlanoMensalEspecialService {
  async visualizarParaPlanoEspecial(matriculaId, mes_id, periodo_id, disciplina_id) {
    const response = await Axios().get(
      `gestoes-de-aulas/${matriculaId}/${mes_id}/${periodo_id}/${disciplina_id}/visualizar-para-plano-especial`
    );
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(
      "planos-bimestrais/plano-mensal-especial/criar-ou-atualizar",
      {
        plano,
      }
    );
    return response.data;
  }

  async planoMensalEspecial(gestaoDeAulaId, periodo, mes_id, disciplina_id) {
    const response = await Axios().get(
      `planos-bimestrais/plano-mensal-especial/${gestaoDeAulaId}/${periodo}/${mes_id}/${disciplina_id}`
    );
    return response.data;
  }
}

export default new PlanoMensalEspecialService();
