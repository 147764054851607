<template>
  <main-template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Gestão de Mensalidades</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :items="matriculas ? matriculas : []"
            :loading="loadingMatriculas"
            :item-text="
              (matricula) =>
                `${matricula.aluno.codigo} - ${matricula.aluno.nomecompleto} - ${matricula.turma.descricao}`.toUpperCase()
            "
            :filter="searchQuery"
            label="Qual matrícula você quer selecionar?"
            return-object
            dense
            cache-items
            hide-no-data
            solo
            autofocus
            @change="selecionarMatricula"
          />
        </v-col>
      </v-row>
      <v-row v-if="loadingParcelas">
        <v-col cols="12">
          <v-skeleton-loader loading type="table" />
        </v-col>
      </v-row>
      <v-row v-if="parcelas">
        <v-col cols="12">
          <e-snippet-matricula :matricula="matriculaSelecionada" />
          <v-data-table
            :headers="table.headers"
            :items="parcelas ? parcelas : []"
            :items-per-page="12"
          >
            <template v-slot:item.referencia="{ item: parcela }">
              {{ parcela.referencia | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
            </template>
            <template v-slot:item.valor="{ item: parcela }">
              {{ parcela.valor | currency }}
            </template>
            <template v-slot:item.valorpago="{ item: parcela }">
              {{ parcela.valorpago | currency }}
            </template>
            <template v-slot:item.situacao="{ item: parcela }">
              <e-situacao-parcela :parcela="parcela" />
            </template>
            <template v-slot:item.actions="{ item: parcela }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="parcela.estaPaga || parcela.estaNegociada"
                    x-small
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openModalBaixarParcela(parcela)"
                  >
                    <v-icon small> fa fa-download </v-icon>
                  </v-btn>
                </template>
                <span>Baixar parcela</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </main-template>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import searchQuery from "@/plugins/searchQuery";

export default {
  name: "MensalidadesPage",
  data() {
    return {
      searchQuery,
      loadingMatriculas: true,
      loadingParcelas: false,
      modalBaixarParcela: { open: false, parcela: null },
      table: {
        headers: [
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
          { text: "Valor Pago", value: "valorpago" },
          { text: "Situação", value: "situacao", align: "center" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("Mensalidades", ["matriculaSelecionada", "parcelas", "parcelaSelecionada"]),
    ...mapGetters("Matriculas", ["matriculas"]),
  },
  mounted() {
    this.loadMatriculas();
  },
  methods: {
    selecionarMatricula(matricula) {
      if (matricula !== this.matriculaSelecionada) {
        this.loadParcelas(matricula);
        this.$store.commit("Mensalidades/setMatriculaSelecionada", matricula);
      }
    },
    async loadParcelas(matricula) {
      this.loadingParcelas = true;
      this.$store.commit("Mensalidades/setMatriculaSelecionada", null);
      try {
        const matriculaDetalhada = await this.$services.matriculasService.getDetails(matricula.id);
        this.$store.commit("Mensalidades/setMatriculaSelecionada", matriculaDetalhada);
      } catch (error) {
        this.$handleError(error);
      }
      this.loadingParcelas = false;
    },
    async loadMatriculas() {
      this.loadingMatriculas = true;
      try {
        await this.$services.matriculasService.syncAll();
      } catch (error) {
        this.$handleError(error.message || error.response.data.error.message);
      }
      this.loadingMatriculas = false;
    },
    openModalBaixarParcela(parcela) {
      this.$store.commit("Mensalidades/setParcelaSelecionada", parcela);
      this.$router.push({
        name: "mensalidades.baixar",
        params: { prestacao_id: this.parcelaSelecionada.id },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!from || from.name !== "mensalidades.baixar") {
      store.commit("Mensalidades/setMatriculaSelecionada", null);
      store.commit("Mensalidades/setParcelaSelecionada", null);
    }
    next();
  },
};
</script>
