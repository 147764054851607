<template>
  <v-overlay :value="status" opacity="0.9">
    <v-alert
      :color="overlay.descricao?.toUpperCase() == 'PLANO INVÁLIDO' ? 'red' : 'green'"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-alert"
      max-width="600px"
      prominent
    >
      <v-col>
        Esse plano está com status de <strong>{{ overlay.descricao?.toUpperCase() }}</strong
        >, por isso não pode mais ser alterado! <br />
        Para mais informações, entre em contato com o coordenador.
      </v-col>
      <v-col>
        <v-btn @click="status = !status">Visualizar Plano</v-btn>
      </v-col>
    </v-alert>
  </v-overlay>
</template>

<script>
export default {
  props: ["overlay"],
  data() {
    return {
      status: true,
    };
  },
  watch: {
    overlay(new_elem) {
      if (new_elem.descricao !== "Aguardando confirmação" || new_elem.descricao !== "") {
        return (this.status = true);
      }
      return (this.status = false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
