import Axios from "@/plugins/Axios";

export class HabilidadeService {
  async procurarHabilidade(query, codigo_disciplina) {
    const response = await Axios().get(
      `planos-bimestrais/habilidades?query=${query}&codigo_disciplina=${codigo_disciplina}`
    );
    return response;
  }
}

export default new HabilidadeService();
