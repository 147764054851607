<template>
  <v-avatar color="blue" class="white--text">
    <v-img v-if="src" :src="src" />
    <span v-if="name">{{ name.substr(0, 1) }}</span>
    <span v-if="current">{{ (user ? user.name : "...").substr(0, 1) }}</span>
  </v-avatar>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "EUserAvatar",
  props: {
    current: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
};
</script>
