<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    relatorio: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    recuperarLabels(text) {
      return Object.keys(text).map((item) => {
        return item;
      });
    },
    recuperarDados(obj) {
      return Object.keys(obj).map((key) => {
        return obj[key].length;
      });
    },
    recuperarCor(obj) {
      const letras = ["a", "b", "c", "d", "e", "f", 1, 2, 3, 4, 5, 6, 7, 8, 9];
      const rgb = ["r", "g", "b"];
      const cor = [];
      let hex = [];
      Object.keys(obj).forEach((index, key) => {
        hex = rgb.map(() => {
          return `${letras[Math.floor(Math.random() * letras.length)]}${
            letras[Math.floor(Math.random() * letras.length)]
          }`;
        });
        cor[key] = `#${hex.join("")}`;
      });
      return cor;
    },
  },
  data() {
    return {
      chartData: {
        labels: this.recuperarLabels(this.relatorio),
        datasets: [
          {
            backgroundColor: this.recuperarCor(this.relatorio),
            data: this.recuperarDados(this.relatorio),
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
