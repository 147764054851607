<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'acessoResponsavel.home' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Métricas do(a) aluno(a)
        </h1>
      </v-col>

      <v-divider class="mx-4"></v-divider>

      <v-col cols="12">
        <e-desempenho :matricula="data.matricula" :notas="data.medias"></e-desempenho>
      </v-col>

      <v-col cols="12" v-if="data_faltas.porcentagem_faltas >= 15">
        <v-card class="mx-auto my-3 pl-5">
          <span class="textoPorcentagem">
            A turma em que você está matriculado(a) possui {{ data_faltas.quantAulasTotal }} aulas,
            e suas faltas correspondem a
          </span>
          <span class="numeroPorcentagem">{{ data_faltas.porcentagem_faltas }}%.</span>
        </v-card>
      </v-col>

      <v-col cols="6" class="mx-0">
        <v-chip color="indigo" label text-color="white" class="mb-3">
          Desempenho da(s) média(s) do(a) aluno(a)
        </v-chip>
        <e-chart-desempenho :disciplinas="data.disciplinas" :notas="data.medias">
          >
        </e-chart-desempenho>
      </v-col>
      <v-col cols="6" class="mx-0">
        <v-chip color="indigo" label text-color="white" class="mb-3">
          Desempenho de faltas do(a) aluno(a)
        </v-chip>
        <e-chart-faltas-aluno :data="data_faltas"></e-chart-faltas-aluno>
      </v-col>
      <v-col class="mt-12">
        <v-alert border="left" color="indigo" dark>
          {{ frasesGerar }}
        </v-alert></v-col
      >
    </v-row>
  </main-template>
</template>

<script>
import EDesempenho from "@/components/EDesempenho.vue";
import EChartDesempenho from "../Templates/components/EChartDesempenho.vue";
import EChartFaltasAluno from "../Templates/components/EChartFaltasAluno.vue";

export default {
  components: { EChartDesempenho, EDesempenho, EChartFaltasAluno },
  created() {
    this.pegarMediasdaMatricula();
    this.pegarDadosDeFaltas();
  },
  data() {
    return {
      data: {},
      data_faltas: {},
      show: false,
      frases: [
        "Lembre-se que o esforço, nos estudos, será a recompensa no futuro.",
        "As sementes de uma vida de estudos, transformam-se em frutos no sucesso.",
        "Nunca tente ser melhor que alguém, mas sim melhor que a pessoa a qual você é hoje.",
        "Não tenha medo de arriscar, sua falha de hoje será uma lição para uma nova tentativa.",
      ],
    };
  },
  methods: {
    async pegarMediasdaMatricula() {
      this.$loaderService.open("Pesquisando alunos...");
      try {
        this.data = await this.$services.matriculasService.getMediasdaMatricula(
          this.$route.params.matricula_id
        );
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async pegarDadosDeFaltas() {
      this.$loaderService.open("Pesquisando alunos...");
      try {
        this.data_faltas = await this.$services.matriculasService.getFaltasdaMatricula(
          this.$route.params.matricula_id
        );
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
  computed: {
    frasesGerar() {
      const frase_exibir = Math.floor(Math.random() * this.frases.length);
      return this.frases[frase_exibir];
    },
  },
};
</script>

<style scoped>
.numeroPorcentagem {
  font-weight: bold;
}
</style>
