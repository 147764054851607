<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Pesquisa avançada de alunos
        </h1>
      </v-col>
    </v-row>
    <form @submit.prevent="pesquisaAvancada" class="mt-5">
      <v-row>
        <v-col class="pt-0 pb-0" cols="5">
          <ValidationProvider name="Nome Completo" rules="required">
            <e-label>Nome Completo</e-label>
            <v-text-field v-model="form.nome" dense solo />
          </ValidationProvider>
        </v-col>
        <v-col class="pt-0 pb-0" cols="3">
          <e-label>Número do CPF</e-label>
          <v-text-field v-mask="'###.###.###-##'" v-model="form.cpf" dense solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="4">
          <e-label>Identificação única(INEP)</e-label>
          <v-text-field v-model="form.codigo_inep" dense solo> </v-text-field>
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[0]" cols="12">
          <ValidationProvider name="Filiação 1" rules="required" v-slot="{ errors }">
            <e-label>Pai</e-label>
            <v-text-field :error-messages="errors" v-model="form.filiacao2" dense solo />
          </ValidationProvider>
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[1]" cols="12">
          <e-label>Mãe</e-label>
          <v-text-field v-model="form.filiacao1" dense solo />
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[2]" cols="6">
          <e-label>Data de nascimento</e-label>
          <!-- <v-text-field :error-messages="errors" v-model="form.data_nascimento" dense solo /> -->
          <e-date-picker v-model="form.data_nascimento" placeholder="dd/mm/yyyy"></e-date-picker>
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[3]" cols="6">
          <e-label>Sexo</e-label>
          <e-autocomplete
            :items="$constants.sexoList"
            :return-object="false"
            :item-value="(value) => value.id"
            :item-text="(value) => value.descricao"
            v-model="form.sexo_id"
            label="Selecione uma opção"
            dense
            solo
          />
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[4]" cols="6">
          <e-label>Cor/Raça</e-label>
          <e-autocomplete
            :items="$constants.corOuRacaList"
            :return-object="false"
            v-model="form.cor_ou_raca"
            label="Selecione uma opção"
            dense
            solo
          />
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[5]" cols="6">
          <e-label>Nacionalidade</e-label>
          <e-autocomplete
            :items="$constants.nacionalidadeList"
            :return-object="false"
            :item-text="(value) => value"
            :item-value="(value) => value"
            v-model="form.naturalidade"
            label="Selecione uma opção"
            dense
            solo
          />
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[6]" cols="6">
          <e-label>País de nacionalidade</e-label>
          <v-text-field v-model="form.nacionalidade" dense solo />
        </v-col>
        <v-col class="pt-0 pb-0" v-if="tipos_pesquisa_aluno[7]" cols="6">
          <e-label>UF de nascimento</e-label>
          <e-autocomplete
            :items="$constants.estadosList"
            :return-object="false"
            :item-text="(item) => item.codigo"
            :item-value="(item) => item.codigo"
            v-model="form.estadualidade"
            label="Selecione uma opção"
            dense
            solo
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0" cols="12">
          <h3>Selecione os tipos de filtro que deseja fazer na pesquisa</h3>
          <v-row>
            <v-col
              class="pt-0 pb-0 m-0"
              cols="3"
              v-for="(el, index) of $constants.tipos_pesquisa_aluno"
              :key="index"
            >
              <v-checkbox
                v-model="tipos_pesquisa_aluno[index]"
                :label="el.desc"
                @change="(event) => limparTextoEscolha(event, index)"
                :value="el.value"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn type="submit" class="primary mt-3"> Pesquisar </v-btn>
    </form>
    <v-data-table
      :headers="table.headers"
      :items="alunosList"
      :loading="carregandoAlunos"
      :items-per-page="10"
      class="elevation-1 mt-5"
    >
      <template v-slot:body="{ items }">
        <tbody name="scroll-x-transition" is="transition-group" duration="150">
          <tr
            color="primary"
            :class="aluno.estaDeletado ? ['deleted'] : []"
            v-for="aluno of items"
            :key="aluno.id"
          >
            <td>{{ aluno.id }}</td>
            <td>
              <strong>{{ aluno.codigo_inep ? aluno.codigo_inep : "- - -" }}</strong>
            </td>
            <td>
              {{ aluno.nomecompleto ? aluno.nomecompleto : "Carregando..." }}
              <p class="text-caption font-weight-light my-0">
                {{ aluno.fantasia ? aluno.fantasia : "- - -" }}
              </p>
              <v-row class="my-3 mx-0 align-center">
                <p class="text-caption font-weight-light my-0">Turma: {{ aluno.TURMA }}</p>
                <p class="text-caption font-weight-light my-0">
                  &nbsp; Ano: {{ aluno.ano ? aluno.ano : "- - -" }}
                </p>
              </v-row>
            </td>
            <td class="text-left">
              <v-chip color="success" outlined>
                {{ aluno.situacao ? aluno.situacao : "- - -" }}
              </v-chip>
            </td>
            <td>
              <v-tooltip v-if="!aluno.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1 white--text"
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => verMatriculas(aluno)"
                  >
                    <v-icon small>fa fa-people-arrows </v-icon>
                  </v-btn>
                </template>
                <span>Transferir aluno</span>
              </v-tooltip>
              <v-tooltip v-if="aluno.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => recuperarAluno(aluno)"
                  >
                    <v-icon small>fa fa-sync </v-icon>
                  </v-btn>
                </template>
                <span>Recuperar aluno</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <e-drawer-listar-matriculas
      v-if="aluno"
      v-model="openDrawer"
      :aluno="aluno"
      :franquia="aluno.configuracoes"
      :matriculas="matriculas"
    >
    </e-drawer-listar-matriculas>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      alunosList: [],
      form: {},
      aluno: {
        nomecompleto: "",
        TURMA: "",
        sexo_id: "",
      },
      openDrawer: false,
      matriculas: [],
      carregandoAlunos: false,
      tipos_pesquisa_aluno: [],
      value: null,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Identificação Única(INEP)", value: "codigo_inep" },
          { text: "Nome", value: "nomecompleto" },
          { text: "Situação", value: "situacao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  methods: {
    async pesquisaAvancada() {
      this.$loaderService.open("Pesquisando alunos...");
      try {
        this.alunosList = await this.$services.alunosService.pesquisaAvancada(this.form);
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async limparTextoEscolha(value, index) {
      const tipo = this.$constants.tipos_pesquisa_aluno[index].value;
      // verifica se o valor é nulo e limpa para não atrapalhar na pesquisa quando não
      // estiver selecionado no checkbox
      if (!value) {
        this.form[tipo] = value;
      }
    },
    async verMatriculas(aluno) {
      this.openDrawer = !this.openDrawer;
      this.aluno = aluno;
      this.matriculas = await this.$services.alunosService.matriculasAluno(aluno);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
