import Axios from "@/plugins/Axios";

export class PeriodosService {
  async listarPeriodos() {
    const response = await Axios().get("/relatorios/listar-periodos");
    return response.data;
  }

  async listarMeses() {
    const response = await Axios().get("/relatorios/listar-meses");
    return response.data;
  }
}
export default new PeriodosService();
