<template>
  <v-chip label color="blue" class="white--text" v-bind="{ ...$props }" x-small>
    {{ value || user ? (value || user).roles[0].name : "..." }}
  </v-chip>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "EUserRole",
  props: {
    value: {
      type: Object,
    },
    link: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
};
</script>
