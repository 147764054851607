<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'usuarios' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Usuário
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(submitForm)"
        autocomplete="off"
        :disabled="submittingForm"
      >
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Nome Completo" rules="required" v-slot="{ errors }">
              <e-label>Nome Completo</e-label>
              <v-text-field :error-messages="errors" v-model="form.name" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Login de acesso" rules="required" v-slot="{ errors }">
              <e-label>Login de acesso</e-label>
              <v-text-field :error-messages="errors" v-model="form.email" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6" v-if="!editing">
            <ValidationProvider
              name="Senha"
              :rules="editing ? 'min:4|confirmed:password' : 'required|min:4'"
              v-slot="{ errors }"
              vid="password"
            >
              <e-label>Senha</e-label>
              <v-text-field
                :error-messages="errors"
                type="password"
                autocomplete="off"
                v-model="form.password"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6" v-if="!editing">
            <ValidationProvider
              name="Confirme a senha"
              :rules="editing ? 'min:4|confirmed:password' : 'required|min:4|confirmed:password'"
              v-slot="{ errors }"
            >
              <e-label>Confirme a senha</e-label>
              <v-text-field
                :error-messages="errors"
                type="password"
                v-model="form.password_confirm"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <e-label>Tipo</e-label>
              <e-autocomplete
                :items="outrosRolesList"
                :return-object="false"
                :item-value="(role) => role.id"
                :item-text="(role) => role.name"
                v-model="form.roles[0].id"
                @change="setRole"
                label="Selecione uma opção"
                dense
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Franquia(s) do usuário:</e-label>
            <e-autocomplete
              :items="franquiasList"
              :return-object="false"
              v-model="form.franquias_permitidas"
              :item-value="(franquia) => franquia.id"
              :item-text="(franquia) => franquia.descricao"
              :loading="carregandoFranquias"
              label="Selecione uma opção"
              dense
              multiple
              solo
            />
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.loadFormData();
  },

  data() {
    return {
      submittingForm: false,
      form: { roles: [{}], role: null },
      outrosRolesList: [],
      franquiasList: [],
      carregandoFranquias: false,
    };
  },

  watch: {
    form: {
      async handler(formValue) {
        try {
          const cepValue = this.$services.cepService.getCepInteiro(formValue.cep);
          if (cepValue) {
            const localizacao = await this.$services.cepService.buscarDadosDoCep(cepValue);
            this.form.municipio_residencia = localizacao.cidade;
            this.form.uf_residencia = localizacao.estado;
          }
        } catch (error) {
          this.$handleError("Erro ao buscar informações do CEP");
        }
      },
      deep: true,
    },
  },
  methods: {
    setRole(value) {
      this.form.roles = [{ id: value }];
    },
    async submitForm() {
      if (!this.editing && this.form.password !== this.form.password_confirm) {
        this.$toast.warn("As senhas não estão iguais");
        return;
      }
      this.form.especial = Boolean(this.form.especial);
      this.submittingForm = true;
      if (!this.form.password) delete this.form.password;
      try {
        if (this.editing) {
          await this.$services.usuariosService.atualizar(this.form);
        } else {
          await this.$services.usuariosService.criar(this.form);
        }
        this.$toast.success("Usuario Atualizada com sucesso");
        this.$router.push({ name: "usuarios" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadFormData() {
      this.$loaderService.open("Carregando dados");

      try {
        this.carregandoFranquias = true;
        const franquias = await this.$services.franquiasService.getAllFranquias();
        this.franquiasList = franquias;
        this.carregandoFranquias = false;

        const response = await this.$services.rolesService.listAllRoles();
        this.outrosRolesList = response.data;

        const { usuario_id } = this.$route.params;
        const data = await this.$services.usuariosService.getUsuario(parseInt(usuario_id, 10));

        if (!data.data) {
          return;
        }

        const usuario = data.data;
        this.form = {
          ...usuario,
        };
      } catch (error) {
        this.$handleError(error);
      }

      if (this.form.id) {
        this.editing = true;
      }

      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
