<template>
  <div>
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-chip label color="error" small>{{ matricula.situacao }}</v-chip>
    </template>
    <template v-else>
      <span v-if="!mostrarResultado">- - -</span>
      <v-chip label color="error" small v-else-if="resultFinal < this.notaMinima">REPROVADO</v-chip>
      <v-chip label color="success" small v-else>APROVADO</v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    notas: {
      type: Object,
      required: true,
    },
    notaMinima: {
      type: Number,
      default: 6,
    },
    matricula: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mostrarResultado() {
      return !this.estaVazio(this.notas.nota_final);
    },
    resultFinal() {
      const result_final = parseFloat(this.notas.nota_final, 10) || 0;
      const prova_final = parseFloat(this.notas.rec_3, 10) || 0;
      let soma = result_final;
      if (prova_final > 0) {
        soma = (result_final + prova_final) / 2;
      }

      return soma;
    },
  },
  methods: {
    estaVazio(n) {
      return n === undefined || n === null;
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>
