import Axios from "@/plugins/Axios";

export class PlanoMensalInfantilService {
  async visualizarParaPlano(gestaoDeAula) {
    const response = await Axios().get(
      `gestoes-de-aulas/${gestaoDeAula}/visualizar-para-plano-infantil`
    );
    return response.data;
  }

  async planoMensalInfantilCreateOrUpdate(plano) {
    const response = await Axios().post(
      "planos-bimestrais/plano-mensal-infantil/criar-ou-atualizar",
      {
        plano,
      }
    );
    return response.data;
  }

  async planoMensal(gestaoDeAulaId, periodo, mes_id) {
    const response = await Axios().get(
      `planos-bimestrais/plano-mensal-infantil/${gestaoDeAulaId}/${periodo}/${mes_id}`
    );
    return response.data;
  }
}

export default new PlanoMensalInfantilService();
