import Axios from "@/plugins/Axios";
import Plano from "../Models/Plano";

export class PlanosService {
  async index(pageNumber, query, turma, disciplina, mes, periodo) {
    const planos = await Axios().get(
      `planos/planos-mensais?page=${pageNumber}&query=${query}&turma=${turma}&disciplina=${disciplina}&mes=${mes}&periodo=${periodo}`
    );
    const response = planos.data.data.map((plano) => new Plano(plano));
    return { planos: response, data: planos.data };
  }

  async show(planoId) {
    const response = await Axios().get(`planos/planos-mensais/${planoId}/visualizar`);
    return response;
  }

  async update(planoId, statusId) {
    const response = await Axios().put(
      `planos/planos-mensais/${planoId}/${statusId}/atualizarSituacao`
    );
    return new Plano(response.data);
  }

  async pdf(planoId) {
    // const response = await Axios()
    //   .get(`planos/planos-mensais/${planoId}/relatorio`, {
    //     responseType: "arraybuffer",
    //   })
    //   .then((response) => {
    //     const blob = new Blob([response.data], { type: "application/pdf" });
    //     const link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = `plano_mensal_${planoId}.pdf`;
    //     link.click();
    //   });
    // return response;

    const base64 = await Axios()
      .get(`planos/planos-mensais/${planoId}/relatorio`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));

    return `data:application/pdf;base64, ${base64}`;
  }

  async disciplinas() {
    const response = await Axios().get(`planos/planos-mensais/disciplinas`);
    return response;
  }

  async turmas() {
    const response = await Axios().get(`planos/planos-mensais/turmas`);
    return response;
  }

  async meses() {
    const response = await Axios().get(`planos/planos-mensais/listarMeses`);
    return response;
  }

  async periodos() {
    const response = await Axios().get(`planos/planos-mensais/listarPeriodos`);
    return response;
  }
}

export default new PlanosService();
