<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'historico.index', params: { aluno_id: aluno_id } })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Histórico do Aluno
        </h1>
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col class="py-0" cols="8">
          <e-label>Aluno</e-label>
          <v-text-field v-model="selectedStudent" label="Aluno" dense solo disabled />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Identificação única(INEP)</e-label>
          <v-text-field v-model="inep" label="INEP" type="number" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Escola</e-label>
          <v-text-field v-model="escola" label="Escola" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Município</e-label>
          <v-text-field v-model="municipio" label="Município" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Estado</e-label>
          <v-text-field v-model="estado" label="Estado" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Ano de conclusão</e-label>
          <v-text-field v-model="ano" label="Ano" type="number" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Série</e-label>
          <v-text-field v-model="serie" label="Série" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Modalidade</e-label>
          <v-text-field v-model="modalidade" label="Ensino Regular / EJA" dense solo />
        </v-col>
        <v-col class="py-0" cols="5">
          <e-label>Disciplinas</e-label>
          <v-text-field
            v-for="(disciplina, index) in disciplinas"
            :key="index"
            v-model="disciplinas[index]"
            label="Disciplina"
            dense
            solo
          />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Médias</e-label>
          <v-text-field
            v-for="(nota, index) in medias"
            :key="index"
            v-model="medias[index]"
            type="number"
            label="Média"
            dense
            solo
          />
        </v-col>
        <v-col class="py-0" cols="2">
          <e-label>&nbsp;</e-label>
          <v-btn
            v-for="(nota, index) in medias"
            :key="index"
            @click.prevent="removeField(index)"
            class="mb-7"
            color="error"
            type="submit"
            block
          >
            Excluir
          </v-btn>
        </v-col>
        <v-col class="pb-7 pt-0" cols="3">
          <v-btn @click.prevent="addField" color="primary" type="submit" block
            >Adicionar disciplina</v-btn
          >
        </v-col>
        <v-col class="py-0" cols="12">
          <v-btn @click.prevent="submitForm" color="primary" type="submit" block>Salvar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </main-template>
</template>

<script>
import { $toast } from "vuetify-snackbar-toast";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      id: "",
      aluno_id: "",
      selectedStudent: "",
      escola: "",
      municipio: "",
      estado: "",
      inep: "",
      ano: "",
      serie: "",
      modalidade: "",
      disciplinas: [],
      medias: [],
    };
  },
  methods: {
    async submitForm() {
      this.$loaderService.open("Salvando dados");
      if (!this.formIsValid()) {
        $toast.danger("Preencha a diciplina junto à nota final");
        this.$loaderService.close();
        return;
      }
      if (
        this.disciplinas[this.disciplinas.length - 1] === " " &&
        this.medias[this.medias.length - 1] === " "
      ) {
        this.disciplinas.pop();
        this.medias.pop();
      }
      const historico = {
        id: this.id,
        aluno_id: this.aluno_id,
        escola: this.escola,
        municipio: this.municipio,
        estado: this.estado,
        inep: this.inep,
        ano: this.ano,
        serie: this.serie,
        modalidade: this.modalidade,
        disciplinas: this.disciplinas,
        medias: this.medias,
      };
      try {
        if (this.editing) {
          await this.$services.historicoService.atualizarHistorico(historico);
        } else {
          await this.$services.historicoService.criarHistorico(historico);
        }
        this.$router.push({ name: "historico.index", params: { aluno_id: this.aluno_id } });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    addField() {
      if (
        this.disciplinas[this.disciplinas.length - 1] !== " " &&
        this.medias[this.medias.length - 1] !== " "
      ) {
        this.disciplinas.push(" ");
        this.medias.push(" ");
      } else {
        $toast.danger("É necessário preencher a anterior para cadastrar a pŕoxima");
      }
    },
    removeField(index) {
      this.disciplinas.splice(index, 1);
      this.medias.splice(index, 1);
    },
    formIsValid() {
      let flag = true;
      if (this.disciplinas.length === 0 || this.medias.length === 0) return false;
      this.disciplinas.map((iten) => {
        if (iten === null || iten.length < 1) {
          flag = false;
        }
        return flag;
      });
      this.medias.map((iten) => {
        if (iten === null || iten.length < 1) {
          flag = false;
        }
        return flag;
      });
      return flag;
    },
    async loadData() {
      if (this.editing) {
        const historico = await this.pegarHistorico();
        this.id = historico.id;
        this.selectedStudent = historico.aluno.nomecompleto;
        this.aluno_id = historico.aluno.id;
        this.escola = historico.escola;
        this.municipio = historico.municipio;
        this.estado = historico.estado;
        this.inep = historico.inep;
        this.ano = historico.ano;
        this.serie = historico.serie;
        this.modalidade = historico.modalidade;
        this.disciplinas = historico.disciplinas;
        this.medias = historico.medias;
      } else {
        const aluno = await this.$services.historicoService.pegarAluno(
          this.$router.currentRoute.params.aluno_id
        );
        this.selectedStudent = aluno.nomecompleto;
        this.aluno_id = aluno.id;
        this.addField();
      }
    },
    async pegarHistorico() {
      try {
        const { historico_id } = this.$route.params;
        const historico = await this.$services.historicoService.pegarHistorico(historico_id);
        return historico[0][0];
      } catch (error) {
        return this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
